.social-icons a:hover .fil0 {
  fill: #005b8c !important;
}

.social-icons a {
  display: inline-block;
  vertical-align: middle;
}

.social-icons a img {
  width: 40%;
}

footer .logo-footer {
  max-width: 100px;
}

footer .copy {
  font-size: 12px;
  color: #8bb3c6;
}

footer .button-menu-footer {
  margin-left: auto;
  margin-right: auto;
}

footer {
  background-color: #005b8c;
  padding-top: 40px;
  padding-bottom: 40px;
}

.menu-footer li {
  padding-left: 20px;
  padding-right: 20px;
}

.menu-footer li.destaque a {
  color: #6b6d73;
}

.menu-footer .active a {
  font-weight: 800;
}

.menu-footer li {
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.menu-footer .nav-curso {
  background-color: #00986d;
  color: #fff;
  border-radius: 15px;
}

.menu-footer li a {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.menu-footer li:hover {
  color: #005b8c !important;
  background-color: #fff !important;
}

.menu-footer li a:hover {
  background-color: transparent !important;
  color: #005b8c;
  font-weight: 400;
}

.menu-footer li.active {
  color: #005b8c !important;
  background-color: #fff !important;
}

.menu-footer li.active a {
  color: #005b8c !important;
}

.logo-iso {
  height: 55px;
  margin-left: 16px;
}

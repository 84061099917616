body{
    margin: 0px;
    font-family: 'Roboto', sans-serif;
}

header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

p {
    font-size: 14px;;
    font-family: 'Roboto', sans-serif;
}

.logo-bar {
    height: 75.5px;
    padding-top: 18px;
    padding-left: 26px;
}

.logo-header{
    max-width: 100%;
    height: 54.5px;
}

.title-container{
    height: 300px;
    background-image: url("Drone-51.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    filter: grayscale(100%);
    padding-left: 30px;
    padding-right: 30px;
}

.publi-title{
    color: #FFF;
    text-shadow: 1px 1px #000;

}

.text-container{
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
    background: #F5F5F7;
}

.footer-container{
    height: 300px;
    background-color: #2C5C8C;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #8bb3c6;
}

.footer-img{
    height: 36px;
    margin-bottom: 30px;
}
/* Header logo */
.header-fitec {
  background-color: #fff;
  padding: 5px;
  width: 100%;
  /* position: fixed; */
  z-index: 999;
}

.logo {
  max-width: 150px;
}

.navbar-brand {
  height: auto !important;
  padding-bottom: 0px;
  padding-top: 0px !important;
}

.navbar-nav > li > a {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.menu-bar {
  margin-top: 30px;
}

.menu li {
  padding-left: 20px;
  padding-right: 20px;
}

.menu li.destaque a {
  color: #6b6d73;
}

.menu .active a {
  font-weight: 800;
}

.menu li {
  padding-left: 25px;
  padding-right: 25px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.menu-nav-bar {
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px !important;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  color: #6b6d73;
  text-transform: uppercase;
}

.selo-iso {
  height: 55px;
}

.nav-item {
  padding: 0 !important;
  display: flex;
}

.nav-link {
  padding: 0 !important;
}

.menu .nav-curso {
  background-color: #00986d;
  color: #fff;
  border-radius: 15px;
}

.menu li a {
  color: #6b6d73;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.menu li:hover {
  color: #005b8c !important;
  background-color: #fff !important;
}

.menu li a:hover {
  background-color: transparent !important;
  color: #005b8c;
  font-weight: 400;
}

.menu li.active {
  color: #005b8c !important;
  background-color: #fff !important;
}

.menu li.active a {
  color: #005b8c !important;
}

.dropdown {
  position: static !important;
}

.dropdown-menu {
  /* box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important; */
  /* margin-top: 0px !important;  */
  width: 100% !important;
  background-color: transparent;
  background-color: rgba(48, 48, 48, 0.438);
  margin-top: 10px;
  padding: 0px !important;
  text-align: center;
}

.dropdown-item {
  max-width: 100% !important;
  white-space: normal;
}

.dropdown-menu li {
  padding-top: 75px;
  padding-bottom: 75px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.dropdown-menu li a {
  color: #fff;
  font-size: 10px;
}

.dropdown-menu li:hover a {
  color: #005b8c;
}

/* botões de idioma */
.lng {
  position: absolute;
  margin-top: 10px;
  display: none;
  padding-left: 0;
  right: 0px;
  top: 16px;
}

.lng img {
  margin-bottom: 5px;
  cursor: pointer;
}

.linguagem img {
  cursor: pointer;
}

.linguagem li:hover {
  border: 1px solid #005b8c;
  border-radius: 28px;
  height: 33px;
  margin-bottom: 10px;
}

.language {
  cursor: pointer;
}

.linguagem {
  width: 33px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 10px;
  padding-top: 0px;
}

#lng-image {
  margin-top: 10px;
}

@media (max-width: 950px) {
  .menu-nav-bar {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 12px !important;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    color: #6b6d73;
    text-transform: uppercase;
    gap: 10px;
    padding-left: 10px;
  }

  .lng {
    position: absolute;
    margin-top: 10px;
    display: none;
    padding-left: 0;
    right: 5px;
    top: 16px;
  }

  .linguagem {
    width: 33px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 10px;
    padding-top: 12px;
  }
}

p {
    color: #848484;
    text-align: left;
    font-size: 14px;
    line-height: 20px;  
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.center {
    margin: auto;
    width: 50%;
    padding: 10px;
}
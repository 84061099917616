html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
p,
h2,
h3,
h5 {
  font-family: "Roboto", sans-serif;
}
body {
  line-height: 1;
}
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* @media screen and (max-width: 915px) and (max-height: 420px) {
  html {
    transform: rotate(-90deg);
    overflow-y: hidden;
    position: absolute;
    width: 914px!important;
    height: 411px!important;
    top: 100%;
  }
  .ctn-rounded{
    padding: 5px;
    padding-top: 0;
    width: 7rem;
    height: 10.5rem;
  }
  .ctn-eng {
    padding: 25px;
    min-width: 200px;
    min-height: 250px;
  
  }
  .ctn-inovacao h3 {
    font-weight: 700;
    padding-top: 15px;
    font-size: 11.5px!important;
  }
  .embed-responsive .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .section-sub-page .btn-publi {
    background-color: #005B8C;
    color: #fff;
    font-size: 14px;
    margin-left: 24%;
  }
  .section-sub-page .btn-publi-en {
    background-color: #005B8C;
    color: #fff;
    font-size: 14px;
    margin-left: 40%;
  }
} */

body {
  top: 0px !important;
}

body,
html {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
.animation {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.spinner {
  width: 80px;
  height: 80px;
  background-image: url(../../assets/images/spinner.png);
  background-size: cover;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
}

#load {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.829);
  z-index: 9999;
}

.inicio {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.bg-cinza-1 {
  background-color: #f5f5f7;
}

.bg-cinza-2 {
  background-color: #eaeaeb;
}

.homepage-hero-module {
  border-right: none;
  border-left: none;
  position: relative;
  height: 100%;
}

.no-video .video-container video,
.touch .video-container video {
  display: none;
}

.no-video .video-container .poster,
.touch .video-container .poster {
  display: block !important;
}

.video-container {
  z-index: -1;
  position: relative;
  bottom: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #000;
}

.video-container .poster img {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.video-container .filter {
  z-index: 9;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  background-image: url(../../assets/vegas/overlays/05.png);
  width: 100%;
  height: 100%;
}

.video-container video {
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.video-container video.fillWidth {
  width: 100%;
}

/* .lng {
  position: absolute;
  margin-top: 10px;
  display: none;
  padding-left: 0;
}



.lng img {
  margin-bottom: 5px;
  cursor: pointer;
}

.linguagem img {
  cursor: pointer;
}

.linguagem img:hover {
  opacity: 0.5;
}

.language {
  cursor: pointer;
}
.language:hover {
  opacity: 0.5;
}

.linguagem {
  width: 33px;
  display: inline-block;
  vertical-align: middle;
} */

.header_img {
  background-image: url(../../assets/images/shadow.png);
  background-position: top;
  background-repeat: repeat-x;
}

.vegas-wrapper {
  background-image: url(../../assets/images/shadow.png);
  background-repeat: repeat-x;
  background-position: top;
}

.logo-header {
  text-align: center;
}

.logo-header img {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

#google_translate_element,
.goog-te-banner-frame,
.goog-te-balloon-frame,
#goog-gt-tt,
.goog-te-balloon-frame,
.goog-tooltip,
.goog-tooltip:hover {
  display: none !important;
}

.menu-lateral {
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  background-image: url(../../assets/images/bg-all.svg);
  background-size: cover;
  z-index: 999;
  margin-left: 100%;
}

header .header-icons {
  display: inline-block;
  vertical-align: middle;
}

header .header-icons img {
  padding-left: 5px;
  padding-right: 5px;
}

header .top-header-icons {
  padding-top: 50px;
}

header .bt-menu {
  background-color: none;
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

header .bt-close {
  background-color: none;
  background: none;
  border: none;
  padding-top: 50px;
  padding-right: 30px;
  text-decoration: none;
  cursor: pointer;
}
/* 
.header-fitec {
  background-color: #fff;
  padding: 10px;
  width: 100%; */
/* position: fixed; */
/* z-index: 999;
} */

/* .logo {
  max-width: 150px;
} */

/* .navbar-brand {
  height: auto !important;
  padding-bottom: 0px;
  padding-top: 0px !important;
}

.navbar-nav>li>a {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.menu-bar {
  margin-top: 30px;
}

.menu li {
  padding-left: 20px;
  padding-right: 20px;
}

.menu li.destaque a {
  color: #6B6D73;
}

.menu .active a {
  font-weight: 800;
}

.menu li {
  padding-left: 25px;
  padding-right: 25px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.menu .nav-curso {
  background-color: #00986D;
  color: #FFF;
  border-radius: 15px;
}

.menu li a {
  color: #6B6D73;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.menu li:hover {
  color: #005B8C !important;
  background-color: #FFF !important;
}

.menu li a:hover {
  background-color: transparent !important;
  color: #005B8C;
  font-weight: 400;
}

.menu li.active {
  color: #005B8C !important;
  background-color: #FFF !important;
}

.menu li.active a {
  color: #005B8C !important;
} */

/* .social-icons a:hover .fil0{
  fill: #005B8C !important;
}

.social-icons a {
  display: inline-block;
  vertical-align: middle;
}

.social-icons a img {
  width: 40%;
}

footer .logo-footer {
  max-width: 100px;
} */

/* footer .copy {
  font-size: 12px;
  color: #8BB3C6;
}

footer .button-menu-footer {
  margin-left: auto;
  margin-right: auto;
} */

/* .dropdown {
  position: static !important;
  
}

.dropdown-menu {
   box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important; 
   margin-top: 0px !important;  
  width: 100% !important;
  background-color: transparent;
  background-color: rgba(48, 48, 48, 0.438);
  margin-top: 10px;
  padding: 0px !important;
  text-align: center;
} 

.dropdown-item {
  max-width: 100% !important;
  white-space: normal;
}

.dropdown-menu li{
  
  padding-top: 75px;
  padding-bottom: 75px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.dropdown-menu li a {
  color: #fff;
  font-size: 10px;
}

.dropdown-menu li:hover a {
  color: #005B8C;
} */

.color-azul {
  color: #005b8c;
}

section {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (min-width: 1224px) {
  .section-sobre {
    height: 600px;
    background: url(../../assets/images/img_mulhercelular.jpg) no-repeat center
      left;
    -webkit-background-size: 30% 100%;
    -moz-background-size: 30% 100%;
    -o-background-size: 30% 100%;
    background-size: 50% 100%;
  }
}

section h2 {
  font-size: 36px;
  /* font-weight: bold; */
  text-transform: uppercase;
  padding-bottom: 30px;
}

section p {
  color: #848484;
  font-size: 14px;
  line-height: 20px;
}

section a.link {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 25px 10px 25px;
  font-weight: bold;
  border-radius: 20px;
  text-decoration: none;
}

.section-sobre h2 {
  text-align: center;
  color: #005b8c;
}

.section-sobre a {
  background-color: #005b8c;
  color: #fff;
}

.section-sobre a:hover {
  background-color: #f5f5f7;
  color: #005b8c;
}

.content-home {
  height: 80vh;
}
.content-home h1 {
  font-size: 64px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
}

@media (max-width: 1224px) {
  .content-home h1 {
    font-size: 34px;
    padding: 10px;
  }
}
.content-home h1 span {
  color: #0086ce;
}

.content-home p {
  font-size: 20px;
  text-align: center;
  color: #fff;
}

.section-atuacao {
  display: inline-flex;
  width: 100%;
  background-color: #005b8c;
  background-image: url(../../assets/images/img_backgroundAtuacao.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.section-atuacao h2 {
  color: #fff;
  text-align: center;
}

.section-atuacao h3 {
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .section-atuacao h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 34px !important;
    font-weight: normal;
}
}

.section-atuacao p {
  column-count: 2;
  column-gap: 30px;
  color: #fff;
}

.ctn-atuacao a {
  font-size: 12px;
  display: inline-block;
  padding: 0px 10px;
  border-left: 1px solid #fff;
  color: #fff;
}

.submenu-interno li {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 12px;
}

.submenu-interno li {
  text-decoration: none;
  color: #fff;
  padding: 0px 15px;
  border-left: 1px solid #fff;
}

.ctn-atuacao a:first-child,
.submenu-interno li:first-child {
  border-left: none !important;
}

.submenu-interno li a {
  color: #fff;
}

.section-solucao h2 {
  color: #005b8c;
  text-align: center;
}

.section-solucao .ctn-solucao {
  height: 40vw;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.section-solucao .ctn-solucao:hover {
  background-size: 120%;
}

.section-solucao .ctn-solucao.bg-solucao-1 {
  background-image: url(../../assets/images/img_engenhariaderede.jpg);
}
.section-solucao .ctn-solucao.bg-solucao-2 {
  background-image: url(../../assets/images/img_microrredes.jpg);
}
.section-solucao .ctn-solucao.bg-solucao-3 {
  background-image: url(../../assets/images/img_solucoes.jpg);
}
.section-solucao .ctn-solucao.bg-solucao-4 {
  background-image: url(../../assets/images/img_transformacaodigital.jpg);
}

.section-solucao .ctn-solucao .glass-solucao {
  background-color: #005b8cc4;
  padding: 20px;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  display: block;
  backdrop-filter: saturate(100%) blur(7px);
  -webkit-backdrop-filter: saturate(100%) blur(7px);
}

.section-solucao .ctn-solucao .glass-solucao h6 {
  color: #fff;
  text-align: center;
  display: block;
  width: 100%;
}

.border-radius-25 {
  border-radius: 20px;
  overflow: hidden;
}

.section-projetos {
  background-color: #005b8c;
}

.section-projetos h2 {
  color: #fff;
  text-align: center;
}

.section-projetos .ctn-projeto-titulo {
  padding: 20px;
  background-color: #f5f5f7;
  min-height: 100px;
}
.section-projetos .ctn-projeto-titulo h3 {
  color: #005b8c;
  font-size: 20px;
  font-weight: bold;
}

.section-projetos .ctn-projeto-descricao {
  background-color: #fff;
  padding: 20px;
  min-height: 120px;
}

.section-projetos .ctn-projeto-descricao p {
  color: #005b8c;
  font-size: 14px;
}

.section-ambientes h2 {
  color: #005b8c;
  text-align: center;
}

@media (min-width: 791px) {
  .section-ambientes .bg-ambientes {
    background: url(../../assets/images/ambientes/img_backgroundAmbiente03.jpg)
      no-repeat center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 100%;
  }

  .section-ambientes {
    padding-bottom: 0px !important;
    height: 465px;
    width: 100%;
    padding-top: 90px;
  }
}

@media (max-width: 1224px) {
  .section-ambientes {
    background-image: linear-gradient(#004e77, #004e77 100%);
    background-size: 100% 200px;
    background-repeat: no-repeat;
    background-position: center bottom;
  }
  .section-ambientes .bg-ambientes {
    background-color: #004e77;
  }

  .section-ambientes {
    padding-bottom: 0px !important;
    height: 450px;
    width: 100%;
    padding-top: 90px;
  }
}

.container-fluid-solucoes {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
}

.section-ambientes .ctn-ambiente {
  min-height: 15vw;
  padding: 30px;
  /* border: 1px solid rgba(255, 255, 255, 0.74); */
}

.section-ambientes /*.ctn-ambiente:hover*/ {
  background-color: rgba(255, 255, 255, 0.397);
}

.section-ambientes .ctn-ambiente h3 {
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.section-ambientes .ctn-ambiente img {
  max-height: 35px;
  margin: 10px;
}

.section-oportunidades h2 {
  color: #005b8c;
  text-align: center;
}

.section-oportunidades .bg-oportunidades {
  padding: 50px;
  background-image: url(../../assets/images/img_oportunidades.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-oportunidades .bg-oportunidades p {
  color: #fff;
}

.section-oportunidades h3,
.section-oportunidades h4 {
  color: #005b8c;
}

.section-oportunidades h3 {
  font-weight: bold;
}
.section-oportunidades h4 {
  text-align: right;
}

.section-contato {
  background-color: #eaeaeb;
}

.section-contato h2 {
  color: #005b8c;
  text-align: center;
}

.section-contato .ctn-contato {
  border-radius: 25px;
  background-color: #fff;
  padding: 30px;
  height: 360px;
}

.section-contato .ctn-contato h3 {
  font-size: 18px;
  color: #005b8c;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 15px;
}

@media (max-width: 420px) and (max-height: 915px) {
  .ctn-contato h3 {
    font-size: 22px !important;
  }
  .ctn-contato p {
    font-size: 18px !important;
  }
}
/* footer {
  background-color: #005B8C;
  padding-top: 40px;
  padding-bottom: 40px;
}


.menu-footer li {
  padding-left: 20px;
  padding-right: 20px;
}

.menu-footer li.destaque a {
  color: #6B6D73;
}

.menu-footer .active a {
  font-weight: 800;
}

.menu-footer li {
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.menu-footer .nav-curso {
  background-color: #00986D;
  color: #FFF;
  border-radius: 15px;
}

.menu-footer li a {
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.menu-footer li:hover {
  color: #005B8C !important;
  background-color: #FFF !important;
}

.menu-footer li a:hover {
  background-color: transparent !important;
  color: #005B8C;
  font-weight: 400;
}

.menu-footer li.active {
  color: #005B8C !important;
  background-color: #FFF !important;
}

.menu-footer li.active a {
  color: #005B8C !important;
} */

.section-intro-page {
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-intro-page.bg-fitec {
  background-color: #005b8c;
}

.section-intro-page.bg-governanca {
  background-image: url(../../assets/images/img_governanca.jpg);
}

.section-intro-page.bg-engenharia {
  background-image: url(../../assets/images/img_engenharia.jpg);
}

.section-intro-page.bg-engenharia-redes {
  background-image: url(../../assets/images/img_banner__engenhariaderede.jpg);
}

.section-intro-page.bg-microredes {
  background-image: url(../../assets/images/img_banner__microrredes.jpg);
}

.section-intro-page.bg-transformacao-digital {
  background-image: url(../../assets/images/img_banner_transformacaodigital.jpg);
}

.section-intro-page.bg-iot {
  background-image: url(../../assets/images/img_banner__solucoes.jpg);
}

.section-intro-page.bg-responsabilidade {
  background-image: url(../../assets/images/img_responsabilidadesocial.jpg);
}

.section-intro-page.bg-sustentabilidade {
  background-image: url(../../assets/images/img_sustentabilidade.jpg);
}

.section-intro-page.bg-trabalhe {
  background-image: url(../../assets/images/img_banner__trabalheconosco.jpg);
}
.section-intro-page.bg-inovacao {
  background-image: url(../../assets/images/img_banner__inovacao.jpg);
}

.section-intro-page.bg-publicacao {
  background-image: url(../../assets/images/img_publicacao.jpg);
}

.section-intro-page.bg-fitec-sobre {
  background-image: url(../../assets/images/img_banner__fitec.jpg);
}

.section-intro-page.bg-software {
  background-image: url(../../assets/images/img_banner_desenvolvimentoSoftware.jpg);
}

.section-intro-page.bg-eletronicos {
  background-image: url(../../assets/images/img_banner_desenvolvimentoEquipamentoEletronico.jpg);
}

.section-intro-page.bg-ia {
  background-image: url(../../assets/images/img_banner__inteligenciaArtificial.jpg);
}

.section-intro-page.bg-iot-2 {
  background-image: url(../../assets/images/img_banner__inteligenciaArtificial.jpg);
}

.section-intro-page.bg-td {
  background-image: url(../../assets/images/img_banner__transformacaoDigital.jpg);
}

.section-intro-page.bg-redes-celulares {
  background-image: url(../../assets/images/img_banner__redescelulares.jpg);
}

.section-intro-page.bg-redes-ip {
  background-image: url(../../assets/images/img_banner__redesIP.jpg);
}

.section-intro-page.bg-iot-inovacao {
  background-image: url(../../assets/images/img_banner__iot.jpg);
}

.bg-oportunidades a {
  color: #fff;
  text-decoration: underline;
}

.bg-oportunidades a.link {
  background-color: #fff;
  color: #005b8c !important;
  margin-top: 30px;
}

.bg-oportunidades a:hover {
  color: #fff !important;
  background-color: #005b8c;
  margin-top: 30px;
}

.section-sub-page {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
@media (max-width: 700px) and (max-height: 684px) {
  .section-sub-page {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.ball-fitec {
  background-color: #005b8c;
  color: #fff;
  border-radius: 50%;
  border: 10px solid #005b8c85;
}

.ball-fitec h6 {
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.symbol-fitec {
  color: #005b8c;
  font-weight: bold;
  font-size: 20px;
}

.section-sub-page h2 {
  text-align: center;
  color: #005b8c;
}
.section-intro-page h1 {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.section-sub-page h3 {
  font-weight: bold;
  color: #005b8c;
  padding-bottom: 15px;
}

.section-sub-page h5 {
  font-weight: bold;
  color: #6b6d73;
  padding-bottom: 15px;
  font-size: 14px;
}

.ctn-eng {
  padding: 25px;
  border-radius: 25px;
  background-color: #fff;
  min-height: 330px;
  min-width: 150px;
}
@media (max-width: 420px) and (max-height: 915px) {
  .ctn-eng {
    padding: 25px;
    min-width: 200px;
    min-height: 250px;
  }
}

.ctn-rounded {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  height: 8.6rem;
}

@media (max-width: 420px) and (max-height: 915px) {
  .ctn-rounded {
    padding: 5px;
    padding-top: 0;
    width: 7rem;
    height: 10.5rem;
  }
}

@media (max-width: 395px) and (max-height: 915px) {
  .ctn-rounded {
    padding: 5px;
    padding-top: 0;
    width: 5.5rem;
    height: 10.5rem;
  }
}

.text-ctn-rounded {
  text-align: center;
}
@media (max-width: 420px) and (max-height: 915px) {
  .text-ctn-rounded {
    font-size: 14px;
  }
}

.ctn-inovacao h3 {
  padding-top: 12px;
  font-size: 11.5px !important;
  text-align: center;
  color: #848484;
}

@media (max-width: 420px) and (max-height: 915px) {
  .ctn-inovacao h3 {
    font-weight: 700;
    padding-top: 15px;
    font-size: 9.5px !important;
  }
}

@media (max-width: 395px) {
  .ctn-inovacao h3 {
    font-weight: 700;
    padding-top: 15px;
    font-size: 11.5px !important;
  }
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 70%;
  border: 0;
}
@media (max-width: 420px) and (max-height: 915px) {
  .embed-responsive .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

@media (max-width: 420px) and (max-height: 915px) {
  .embed-responsive-16by9 {
    /* padding-top: 56.25%; */
    width: 350px !important;
  }
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}
@media (max-width: 420px) and (max-height: 915px) {
  .embed-responsive-1by1::before {
    padding-top: 30%;
  }
}

.section-sub-page .btn-publi {
  background-color: #005b8c;
  color: #fff;
  font-size: 14px;
  margin-top: 40px !important;
}
@media (max-width: 420px) and (max-height: 915px) {
  .section-sub-page .btn-publi {
    background-color: #005b8c;
    color: #fff;
    font-size: 16px;
  }
}

@media (max-width: 420px) and (max-height: 915px) {
  .section-sub-page .btn-publi-en {
    background-color: #005b8c;
    color: #fff;
    font-size: 14px;
  }
}

.section-sub-page .btn-publi:hover {
  background-color: rgb(82, 134, 183);
  color: #fff !important;
}

.section-contato .fones {
  padding: 15px;
  border-radius: 10px;
  background-color: #5a5a5a17;
  display: flex;
  align-items: center;
  height: 50px;
}

.section-contato .fones p {
  color: #005b8c;
}
@media (max-width: 420px) and (max-height: 915px) {
  .fones p {
    font-size: 15px !important;
  }
}
@media (max-width: 1224px) {
  .section-contato .fones p {
    font-size: small;
  }
}

.section-contato .fones p b {
  font-weight: bold;
}

.section-presenca h3 {
  font-weight: bold;
  color: #005b8c;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

.carousel-control-prev-icon {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(44, 92, 140)' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(44, 92, 140)' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

#ambiente-prev {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(255, 255, 2155)' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

#ambiente-next {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(255, 255, 255)' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators li {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(44, 92, 140);
  border-radius: 50%;
}

.body {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-inline: 2rem;
  background: #f5f5f7;
}

.textColumns {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 64px;
  flex-direction: row;
  padding-inline: 2rem;
  padding: 5rem;
  background: #f5f5f7;
}
@media (max-width: 700px) {
  .textColumns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    padding-top: 24px;
    gap: 2px;
    background: #f5f5f7;
  }
}

.divider {
  width: 1px;
  border: 0.5px solid #d9d9d9;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 5.5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
@media (max-width: 700px) {
  .imageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 7.5rem;
    gap: 0.1rem;
  }
}

.logoFitec {
  height: 4.8rem;
}

.logoEmbrapii {
  height: 8.2rem;
}

.textBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  font-size: 0.875rem;
}

.textBox > p {
  text-align: justify;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
}

.title {
  color: #004e77;
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 20px;
}

.bulletPoint {
  list-style-type: disc !important;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #848484;
  font-size: 0.875rem;
  text-align: justify;
}

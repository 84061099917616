.arrow {
  position: absolute;
  border-radius: 3px;
  font-size: 26px;
  top: 50%;
}

.arrow:hover {
  background: #869791;
  color: #fff;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

.ant-carousel {
  width: 100%;
  padding: 20px;
}

@media (max-width: 600px) {
  .section-atuacao p {
    column-count: 1;
    column-gap: 30px;
    color: #fff;
  }
}

.carrousel-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  color: #fff;
}

.carrousel-content {
  display: flex;
  width: 100%;
  height: 360px;
  color: #fff;
}

.slide-carrousel-active {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
@media (max-width: 700px) {
  .slide-carrousel-active {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.slide-carrousel-active h2 {
  font-size: 16px;
}

.slide-carrousel- {
  display: none;
}
.text-carrousel {
  display: flex;
  text-align: center;
}

.links-carrousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.column-carrousel {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 20px;
}
@media (max-width: 600px) {
  .column-carrousel {
    padding: 30px;
  }
}

.column-carrousel span {
  text-align: justify;
  font-size: 14px;
  line-height: 1.5;
  columns: auto 2;
}

.nav-button-carrousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
.nav-button-carrousel:hover {
  background: #86979140;
}

.indicators-carrousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #fff;
}

.indicator-carrousel- {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
  background: #86979140;
  margin: 0 5px;
  cursor: pointer;
}
.indicator-carrousel-active {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
  background: #86979140;
  margin: 0 5px;
  cursor: pointer;
  background: #fff;
  
}
